













































































import { Component, Prop } from 'vue-property-decorator';
import { TermView } from '../types';
import { AuthorizationMixin } from '@/views/mixin';
import AppTable from '@/components/AppTable.vue';
import { Pagination } from "@/components/types";

@Component({
  components: { AppTable }
})
export default class PluginTable extends AuthorizationMixin {
  @Prop({ type: Array, default: () => [] }) items!: TermView[];
  @Prop({ type: Function }) itemClasses!: (term: TermView) => string;
  @Prop({ type: Object, default: null }) pagination!: Pagination;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  pag: Pagination = { itemsPerPage: -1 }

  created() {
    if (this.pagination) {
      this.pag = { ...this.pag, ...this.pagination }
    }
  }
}
